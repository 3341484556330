/*===============================
=            Sidebar            =
===============================*/

.sidebar {
  position: relative;
  color: $body-color;
  box-sizing: border-box;
  a {
    color: $blue;
  }
}

.sidebar-section {
  background-color : $sidebar-bg;
  box-sizing       : border-box;
  padding-top      : 1.5em;
  margin-bottom    : $spacer*1.5;
  border-radius    : $spacer;
  border           : 2px solid $green; 
}
.sidebar-section ul {
  list-style-type : none;
  line-height     : 1.8;
  padding         : 0;
  margin          : 0;

  li {
    width: 100%;
    padding: 0;
  }
  .useful-link {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.section-content {
  padding: 1rem;
  text-align: justify;
}

.sidebar .section-content,
.sidebar .sidebar-footer {
  padding: ($spacer / 2) $spacer;
}
.section-header {
  width           : 100%;
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
  align-items     : center;

  > * {
    display        : inline-block;
    vertical-align : middle;
    margin         : 0;
  }
  .section-title {
    margin: 0;
    color: $white;
  }
  .link {
    text-decoration : none;
    color           : $red;
    font-size       : 1rem;
  }
}

.sidebar .section-header {
  background-color: $sidebar-header-bg;
  padding: ($spacer / 2) $spacer;
  color: $white;
  .link {
    color: white;
  }
}

.sidebar-section-link {
  display: block;
  color: inherit;
}

// Attendance
.sidebar-section.attendance {
  margin-bottom: 1.5rem;
}
.attendance .section-content {
  margin-righht: 10px;
  display: flex;
  flex-direction: row;
  color: $blue;
  p{
    font-size: 0.75rem;
  }
  > * {
    /*flex-basis: 30%;*/
  }
}
//Achievements
.sidebar-section.achievements {padding: 0;}
.sidebar-section.achievements .section-content ul li {
  margin-bottom: 1rem;
  display: inline-block;
  img {
    display: block;
    margin: 0 auto;
  }
}



// Inner Sidebar */
.inner-sidebar {
  position: relative;
  li {
    a {
      width: 100%;
      color: white;
    }
    &::after {
      content: '';
      font-family: "FontAwesome";
      position: absolute;
      right: $spacer * 2;
      color: white;
    }
  }
}

.sidebar-section.search {
  .section-content {
    padding-top: 1rem;
  }
  label {
    display: none;
    visibility: hidden;
    clip: rect(0,0,0,0);
  }
  input {
    border-radius: 4px;
    border: none;
    min-height: 2.5em;
  }
  input[type="text"] {
    width: 100%;
    max-width: calc(100% - 50px);
    padding-left: 0.5em;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }
  input[type="submit"],
  .btn.btn-secondary {
    position: relative;
    vertical-align: top;
    margin-left: 0;
    min-height: 40px;
    transform: translateX(-4px);
    font-family: FontAwesome;
    background-color: $gray-500;
    border-color: $gray-500;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    box-shadow: none;
  }
}


.sidebar-section.video {
  margin-bottom: 1.5rem;
  .section-content {
    text-align: center;
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.sidebar iframe.video {
  border: 4px solid white;
  -webkit-appearance: none;
  appearance: none;
  object-fit: cover;
  background-color: #0a0a0a;
  width: 460px;
  max-width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}




.sidebar .twitter-icon {
  max-width: 2em;
  height: auto;
}

// Quotes
.sidebar-section.quotes {
  margin-bottom: 1.5rem;
}
.sidebar .blockquote {
  @include clearfix;
  cite {
    float: right;
  }
}



.sidebar-section.twitter .section-content {
  padding-top: 1em;
  padding-bottom: 1em;
}



.sidebar-section.twitter + .sidebar-section.search,
.sidebar-section.search + .sidebar-section.attendance {
  margin-top: 1.5rem;
}


