/**
 * Add any CSS here to override any plugins or frameworks css
 *
 * CSS only Bootstrap config should be done in /styles/modules/_bootstrap-vars.scss
 */

.section-content ul {
    padding: 0 0 0 30px;
}
.section-content li {
    margin-left: 10px;
}
.section-content {
	.tab-content {
		> .tab-pane {
			display:none;
		}
		> .active {
			display:block;
		}
	}
}

.dropdown-menu.show{
    width: auto;
}
.table td, .table th {
    border-left: 1px solid $gray-300;
}
.table td:last-child, .table th:last-child {
    border-right: 1px solid $gray-300;
}
.table tr:last-child {
    border-bottom: 1px solid $gray-300;
}

.fa.fa-search {
    font-size: 20px;
}
.section-header .fa,
.fa {
    font-size: 30px;
}
.nav-tabs {
    flex-wrap: nowrap;
}
.tab-content {
    flex-basis: 50%;
}
.nav-tabs .nav-item{
font-family: $font-family-heading;
font-size: 2rem;
flex-basis:50%;
text-align: center;
}
.input-group-prepend, .input-group-append {
    display: block;
}
img.float-right {
    margin: 5px 0 5px 20px;
}
img.float-left {
    margin: 5px 20px 5px 0;
}

// @media screen and (min-width: map-get($grid-breakpoints, xl)){
//     #google_translate_element{
//       position : absolute;
//       top      : 20px;
//       left     : 250px;
//     }
// }