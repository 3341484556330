/*==============================
=            Footer            =
==============================*/


.site-footer {
  padding-top      : $spacer * 2;
  background-color : $footer-bg;
  font-family      : $font-family-sans-serif;
  color            : $footer-text-color;
  overflow-x       : hidden;
}

.site-footer .btn.btn-primary {
  margin-top: 1em;
}

.site-footer h4 {
  font-family: $font-family-sans-serif;
  color: $darkblue;
}
.site-footer h3 {
  font-weight: bold;
  margin-top: 10px;
}
.site-footer p {
  margin-bottom: $spacer;
  color: $white;
}
.footerAddress ul {
  padding: 0;
  margin: 0;
  li {
    border-bottom: 1px dotted $white;
    list-style-type: none;
    padding-bottom: 5px;
  } 
}
.footerAddress .fa{
  font-size: 16px;
  margin-right: 5px;
}
.site-footer {
  // font-style: italic;
  a {
    color: $white;
  }
  p {
    &:first-of-type {
      padding-top: 0;
    }
    padding: .25rem 0 .25rem 0;
  }
}
.site-footer .copyright {
  margin-top: 1.5em;
  background-color: $footer-copyright;
  padding: 1em;
  color: $white;
  a {
    color: $white;
  }

}
.site-footer .container {
  @include clearfix;
}

@include media-breakpoint-down(md) {
  .address-col,
  .contact-col {
    padding-left: $spacer;
    padding-right: $spacer;
  }
}

// Social icons list
.social-col .list {
  font-size: 1.5em;
  @include media-breakpoint-down(md) {
    padding-left: $spacer;
    padding-right: $spacer;
  }
}


.footer-logo {
  max-width: 12rem;
  height: auto;
  max-height: 5rem;
  object-fit: cover;
  border: 2px solid rgba(0,0,0,0.7);
  padding: 0;
  &:not(:last-child){
    margin-right: 0.5em;
  }
}


// custom input for directions
.sidebar .form,
.site-footer .form {
  input[type="submit"] {
    font-family: "FontAwesome", sans-serif;
  }
}

.site-footer .form .form-control {
  width                      : auto; // prevents submit button from wrapping to next line
  display                    : inline-block;
  background-color           : $white;
  border-color               : $white;
  color                      : $gray-500;
  border-top-right-radius    : 0;
  border-bottom-right-radius : 0;
  border-top-left-radius     : 1rem;
  border-bottom-left-radius  : 1rem;
  margin-bottom              : 0;

  @media screen and (min-width: 576px) {
    max-width: 180px;
  }
  @media screen and (min-width: 640px) {
    max-width: 300px;
  }
  @media screen and (min-width: 992px) {
    font-size: 0.875em;
    max-width: 160px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1em;
    max-width: 200px;
  }
  // @media screen and (min-width: 1368px) {
  //   max-width: 220px;
  // }
}
.site-footer .form button[type="submit"] {
  position                   : relative;
  display                    : inline-block;
  background-color           : $footer-button-color;
  border                     : none;
  color                      : white;
  border-top-left-radius     : 0;
  border-bottom-left-radius  : 0;
  border-top-right-radius    : 1rem;
  border-bottom-right-radius : 1rem;
  vertical-align             : top; // Makes button appear inline with text input
  min-height                 : 38px;
}


.social-col,
.address-col,
.contact-col {
  margin-bottom: 1.5rem;
}
.address-col {
  border-bottom: 2px solid $darkblue;
}
// .contact-col img {
//   min-width: 100%;
// }

@media screen and (min-width: 992px) {
  .social-col,
  .address-col,
  .contact-col {
    margin-bottom: 0;
  }
  .address-col {
    border-right: 2px solid $darkblue;
    border-bottom: none;
  }
}

.contact-us .form-control{
  margin-bottom : $spacer*1.5;
}
#searchBtn.btn  {
  background-color: $white;
  box-shadow: none;
}