/*================================
=            Carousel            =
================================*/

// Carousel
// --------

.carousel {
  max-width               : 100%;
  border                  : none;
  border-radius           : 0.25rem;
  border-top-right-radius : 0;
  margin-bottom           : $spacer * 2;
  margin-top              : $spacer * 1;
}
.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  width: 5%;
}
.carousel-inner {
  border-radius           : 1rem;
}

/*=====  End of Carousel  ======*/
