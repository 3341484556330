
.site-header {
  @include clearfix;

  background-color    : $header-color;
  min-height          : $header-height;
  border-top          : 30px solid $header-border-color-top;
  border-bottom       : 15px solid $header-border-color-bottom;
  position            : relative;

  .row{
    align-items: flex-end;

    @media screen and (min-width:480px) {
      background-size : 350px;
    }

    @media screen and (min-width:650px) {
      background-size : 450px;
    }

    @media screen and (min-width: 900px) {
      background-size : auto;
      background-position : top 10px right 20px;
    }

  }
}

// @media screen and (min-width: 768px) {
//   .site-header {
//     border-top: 2rem solid $green;
//   }
// }


/*
// Ofsted badge
.site-header::after {
  content: '';
  position: absolute;
  width: 89px;
  height: 90px;
  top: 2rem;
  right: 0;
  background-image: url('/assets/images/ofsted-badge.png');
  background-size: cover;
  transform: translateY(-50%);
  @media screen and (max-width: 768px) {
    width: 54px;
    height: 54px;
  }
}
@media screen and (min-width: 1600px) {
  .site-header::after {
    top: 50%;
  }
}
*/

// Logo

.logo-wrapper{
    overflow      : hidden;
    height        : auto; 
      img{
        margin: 0 auto;
        display: block;
      }


    @media screen and (min-width:650px) {
      
      height : auto;
    }


    @media screen and (min-width: 900px) {
     
      height : auto;
      display: block;
      text-align: center;
    }

    // .logo{
    //     width: 100%;
    // }
}


// @media screen and (max-width: 992px) {
//   .logo {
//     max-width: 10rem;
//   }
// }
// @media screen and (max-width: 1200px) {
//   .logo {
//     max-width: 12rem;
//   }
// }




.school-slogan {

  font-family : $font-family-heading;
  color       : $red;
  width       : 100%;
  order       : 5;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;

  // @include responsive-font(5vw, 1.25rem, 3rem, 1.25rem);
  // position    : absolute;
  // right       : 1rem;
  // bottom      : 0;
  // color       : white;

}

@media screen and (min-width: 980px) {
  .school-slogan {
    // float         : right;
    // padding-right : 1em;
    // display       : block;
    // order         : initial;
    // width         : auto;
    font-size: 2rem;
  }
}


@media screen and (min-width: 1040px) {
  .school-slogan {
    // position      : relative;
    // float         : right;
    // padding-right : 1em;
    // display       : block;
    // order         : initial;
    // width         : auto;
    // text-align: right;
    font-size : 2rem;
    top       : 0.5rem;
  }
}


@media screen and (min-width: map-get($grid-breakpoints, $nav-breakpoint)){
  .site-header .navbar {
    /*margin-top      : 1rem;*/
    font-size       : 0.9em;
    justify-content : flex-end;
  }
}


@media screen and (min-width: 1600px) {
  .site-header .navbar {
    
    font-size: 1em;
  }
  .site-header .display-text {
    font-size: 2.4rem;
  }
}
