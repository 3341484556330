/*=================================
=            Home page            =
=================================*/


.home-page {
  overflow-x: hidden;
  .news,
  .events {
    margin-bottom: 1.5rem;
    border-radius: 1rem;
  }
  .carousel {
    border-radius: 1rem;
  }
}


// Carousel
// --------

.carousel-item img {
  width: 100%;
}

.carousel {
  position: relative;
  box-shadow: none;
  margin-top: 0;
  padding-top: $spacer * 1;
}

@media screen and (min-width: 480px) {
  .carousel::before {
    width: 64px;
    height: 64px;
  }
}



// Welcome
// -------

.welcome {

  margin-bottom       : 1.5rem;
  background-image    : url($welcomeImage);
  background-position : right top;
  background-size     : 150px;
  background-repeat   : no-repeat;

  a {
    color: inherit;
    text-decoration: underline;
  }

  .display-text {
    font-size: 2.8125em;
  }


  .lead,
  p:first-child {
    font-size  : $leadText;
    color      : $blue;
    margin-top : 1rem;
    // margin-bottom: 1rem;
  }

  @include media-breakpoint-up(md) {
    background-position: right $spacer bottom $spacer;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 7.5rem;
    background-position: center bottom $spacer;
  }
}

// News and Events
//----------------

.news,
.events,
.galleries {
  padding-left: $spacer * 2;
  padding-right: $spacer * 2;
  color: $indigo;
}

.news {
  background-color: white;
}

.events,
.galleries {
  margin-top: 1.5em;
  background-color: white;
}





// @media screen and (min-width: 992px) {
//   .notes {
//     position:relative;
//     &::before {
//       position: absolute;
//       content: '';
//       width: 30px;
//       height: 30px;
//       top: -1.5em;
//       left: 50%;
//       transform: translateX(-50%);
//       background-image: url('/assets/images/pin-green.png');
//       background-size: contain;
//       background-repeat: no-repeat;
//     }
//   }
// }

// .galleries {
//   position: relative;
//   .row {
//     padding-right: -0.5em;
//     padding-left: -0.5em;
//   }
//   [class^="col"] {
//     padding-left: 0.5em;
//     padding-right: 0.5em;
//   }
//   &::before {
//     position: absolute;
//     content: '';
//     width: 30px;
//     height: 30px;
//     top: -0.5em;
//     left: 0.5em;
//     background-image: url('/assets/images/pin-red.png');
//     background-size: contain;
//     background-repeat: no-repeat;
//   }
//   &::after {
//     position: absolute;
//     content: '';
//     width: 30px;
//     height: 30px;
//     top: -0.5em;
//     right: 0.5em;
//     background-image: url('/assets/images/pin-blue.png');
//     background-size: contain;
//     background-repeat: no-repeat;
//   }
// }
.galleries img {
  margin-bottom: 1rem;
}

.news .section-header,
.events .section-header {
  background-color: transparent;
  margin-top: 0px;
  font-family: $headings-font-family;
  font-size: 2em;

  .link {
    color: white;
  }
}

.welcome .section-header .title {
  background-color: $blue;
  padding-left: $spacer;
}
.welcome .section-header .title:after {
  position: absolute;
  /*background-image:  url(/assets/images/icon-books.png);
  background-repeat: no-repeat;
  background-size: contain;*/
  left: 78%;
  top: -10px;
  width: 150%;
  height: 150%;
}
.events .section-header .title {
  background: $blue;
  padding-left: $spacer;
}
.news--title {
  margin-bottom: 0;
  font-size: $titleText;
}
.news-intro {
  padding-bottom: $spacer / 2;
}

.news-row {
  margin-bottom: 2rem;
}

.news li,
.news-home li {
  list-style-type: disc;
  padding: 0;
  max-height: 1.5rem;
  margin-bottom: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $blue;
}


// Galleries
// ---------

.galleries {
  // background-color: $gray-200;
  padding: $spacer * 2;
}
.galleries .section-header {
  background-color: transparent;
  padding: 0;
}
.galleries .section-content {
  padding: 1rem;
}

// Maps
// ----

.map-img {
  box-sizing: border-box;
  border: 0.25em solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  margin-top: 0.5rem;
}


// Make headings larger on tablet and desktop

// @media screen and (min-width: 768px) {
//   .welcome-title,
//   .news .section-header,
//   .events .section-header {
//     font-size: 2.5em;
//   }
// }
.policy-item {
    padding-top: 1rem;
}




//For news section and useful info
.news-home {
  display: flex;
  justify-content: space-between;
  .latestnews .title {
    background-color: $red;
    padding-left: $spacer;
  }
  .latestnews h3.latest {
    padding: 1rem;
    margin-bottom: 0px;
    font-size: 1rem;
    a {
      color: $black;
    }
  }
  .latestnews h3:nth-child(even) {
    background-color: #E5ECF8;
  }
  .usefulinfo .title {
    background-color: $red;
    padding-left: $spacer;
  }
  .latestnews,
  .usefulinfo {
    background-color: #FFF;
    border-radius: $spacer;
    
  }
  .latestnews {
    position: relative;
    li {
      a {
        color: $blue;
      }
    }
    li:nth-child(odd) {
      background-color: #E5ECF8;
      margin-left: -0.5em;
      padding-left: 0.5em;
      a {
        color: #026fb0;
      }
    }
  }
  .usefulinfo {
    li {
      a {
        color: $blue;
      }
    }
  }
}
@media screen and (min-width: 300px) {
  .news-home {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    .latestnews,
    .usefulinfo {
      flex-basis: 98%;
      max-width: 98%;
      flex-grow: 1;
    }
    .usefulinfo {
      margin-top: 1rem;
    }
  }
}
@media screen and (min-width: 769px) {
  .news-home {
    display: flex;
    flex-wrap: nowrap;
    /*justify-content: space-between;*/
    .latestnews,
    .usefulinfo {
      flex-basis: 49%;
      max-width: 49%;
      flex-grow: 0;
      flex-shrink: 1;
    }
    .usefulinfo {
      margin-top: 0rem;
    }
  }
}

.news-home {
  margin-top: 2rem;
  .latestnews .title {
    position: relative;
    &:before, &:after {
      position          : absolute;
      content           : '';
      width             : 100%;
      height            : 100%;
    }
    &:before {
      background: url(/assets/images/img-pinRed.png) no-repeat;
      top               : -0.3em;
      left              : 0.1em;
    }
    &:after{
      background: url(/assets/images/img-pinBlue.png) no-repeat top right;
      top               : -0.3em;
      margin-left        : -17px;
    }
  }
  .usefulinfo .title {
    position: relative;
    &:before, &:after {
      position          : absolute;
      content           : '';
      width             : 100%;
      height            : 100%;
    }
    &:before {
      background: url(/assets/images/img-pinRed.png) no-repeat;
      top               : -0.3em;
      left              : 0.1em;
    }
    &:after{
      background: url(/assets/images/img-pinBlue.png) no-repeat top right;
      top               : -0.3em;
      margin-left        : -17px;
    }
  }
} 
  .welcome .title.welcome-title {
    position: relative;
    color: $yellow;
    &:before, &:after {
      position          : absolute;
      content           : '';
      width             : 100%;
      height            : 100%;
    }
    &:before {
      background: url(/assets/images/img-pinRed.png) no-repeat;
      top               : -0.3em;
      left              : 0.3em;
    }
    &:after{
      background: url(/assets/images/img-pinBlue.png) no-repeat;
      top               : -0.3em;
      left               : 95%;
    }
  }

  .events .title {
    position: relative;
    &:before, &:after {
      position          : absolute;
      content           : '';
      width             : 100%;
      height            : 100%;
    }
    &:before {
      background: url(/assets/images/img-pinBlue.png) no-repeat;
      top               : -0.3em;
      left              : 0.1em;
    }
    &:after{
      background: url(/assets/images/img-pinRed.png) no-repeat top right;
      top               : -0.3em;
      margin-left        : -17px;
    }
  }
  .gallery .title {
    position: relative;
    padding-left: 70px;
    &:before {
      position          : absolute;
      content           : '';
      width             : 100%;
      height            : 100%;
      top               : 0em;
      left              : 0.5em;
    }
  }


.background{
  //background-color      :$white;
  padding:               0 0 $spacer*2 0;
}

.webp .background{
  background-image : none;
}