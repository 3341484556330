/*
JQuery Simple MobileMenu
https://github.com/Position2/jQuery-Simple-MobileMenu
*/
/*Main style*/


$nav-breakpoint     : md!default;
$burger-color       : #fff;
$background-color   : $blue;
$nav-amination-speed : 0.18s;

// Hide border and background is a button
.nav-toggle{
  background-color : transparent;
  border-color     : transparent;
}
/*Hamburger Animation */
.animated-navbar-toggler {
  position         : fixed;
  top              : $spacer*0.1;
  right            : $spacer*1.2;
  bottom           : auto;
  left             : auto;
  margin           : auto;

  cursor           : pointer;
  height           : 25px;
  transform        : rotate(0deg);
  transition       : all $nav-amination-speed ease-in-out 0s;
  width            : 30px;
  z-index          : 10003;
}
.animated-navbar-toggler span {
  background-color : $burger-color;
  border-radius    : 5px;
  display          : block;
  height           : 2px;
  left             : 0;
  opacity          : 1;
  position         : absolute;
  transform        : rotate(0deg);
  transition       : all $nav-amination-speed ease-in-out 0s;
  width            : 30px;
}

// .animated-navbar-toggler.open span {
//   background-color: $burger-color;
// }

.animated-navbar-toggler span:nth-child(1) {
  top: 0;
}
.animated-navbar-toggler span:nth-child(2),
.animated-navbar-toggler span:nth-child(3) {
  top: 10px;
}
.animated-navbar-toggler span:nth-child(4) {
  top: 20px;
}
.animated-navbar-toggler.open span:nth-child(1) {
  left: 50%;
  top: 18px;
  width: 0;
}
.animated-navbar-toggler.open span:nth-child(2) {
  transform: rotate(45deg);
}
.animated-navbar-toggler.open span:nth-child(3) {
  transform: rotate(-45deg);
}
.animated-navbar-toggler.open span:nth-child(4) {
  left  : 50%;
  top   : 18px;
  width : 0;
}


@media screen and (min-width: map-get($grid-breakpoints, $nav-breakpoint)) {
  .animated-navbar-toggler,
  .sm_menu_outer {
    display: none;
  }
  .mm_main_nav {
    display: flex;
  }
}

.hasChild{
  position: relative;

  &.active{
    position: static;
  }
}

.show-subnav{
  display  : inline-block;
  position : absolute;
  right    : 0;
  top      : 0;
  bottom   : 0;
  margin   : auto;
  width    : 60px;
  cursor   : pointer;
}



/* Sidebar Nav */
@media screen and (max-width: map-get($grid-breakpoints, $nav-breakpoint)){
  // CSS Responsive Nav
  .minimal-menu{
    overflow         : auto;
    left             : -100%;
    bottom           : 0;
    width            : 375px;
    max-width        : 100%;
    height           : 100%;
    background-color : $blue;

    position   : fixed;
    right      : 0;
    top        : 0;
    z-index    : 9999;
    opacity    : 0;
    transition : left 0.8s, opacity 0.5s;

    box-shadow: 0 2px 6px rgba(0,0,0,0.5);
  }

  .minimal-menu.open{
    visibility : visible;
    left       : 0;
    opacity    : 1;

    .navbar-nav{
      display : initial;

    }
    .nav-item{
      opacity : 1;
      border-bottom: 1px solid darken( $blue, 20% );
      .nav-link {
        margin-left: 1rem;
      }
    }

    // fix positioning on mobile menu
    .dropdown-menu {
        position : static!important;
        float    : none;
        padding  : 0;
    }

    .level-1 {
      .dropdown-menu{
        background-color : lighten( $blue, 10%);
      }
    }

    .level-2 {
        .dropdown-menu{
          background-color : lighten( $blue, 20%);
        }

    }
  }
}



/* Sub nav positioning */
// .level2 .dropdown-menu{
//   left: 95%;
//   top: 0;
// }


/*
JQuery Simple MobileMenu
https://github.com/Position2/jQuery-Simple-MobileMenu
*/
/*Main style*/

/*
body.mmactive {
  overflow: hidden;
}

.mobile_menu {
  display: none;
  position: relative;
}

.mm_main_nav{
  display: none;
}

.sm_menu_outer.active .mobile_menu {
  display: block;
}
.sm_menu_outer {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -9;
  width: 100%;
  opacity: 0;
  //background-color: $darkBackground;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
  display: none;
}
.sm_menu_outer.active {
  opacity: 1;
  z-index: 10002;
  display: block;
}
.sm_menu_outer .mobile_menu li.back a {
  padding: 20px 5px 20px 55px;
  background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M16.67%200l2.83%202.829-9.339%209.175%209.339%209.167-2.83%202.829-12.17-11.996z%22%2F%3E%3C%2Fsvg%3E') no-repeat scroll;
  background-position: left 10px center;
  background-size: 20px 20px;
}
.sm_menu_outer .mobile_menu a {
  color: #ffffff;
  display: block;
  // font: 300 18px/140% "Lato", sans-serif;
  letter-spacing: 1px;
  padding: 20px 15px;
  text-transform: uppercase;
  text-decoration: none;
}
.sm_menu_outer .mobile_menu .submenu {
  //background-color: $darkBackground;
  height: 100%;
  position: absolute;
  right: -100%;
  top: 0;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 999;
}
.sm_menu_outer .mobile_menu li.active>.submenu {
  right: 0;
}
.sm_menu_outer .mobile_menu li {
  list-style-type: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0);
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #2a2a2a 20%, #2a2a2a 50%, #2a2a2a 80%, rgba(0, 0, 0, 0) 100%) 0 0 1 0;
  color: #ffffff;
}
//.sm_menu_outer .mobile_menu li.hasChild>a,
.sm_menu_outer .mobile_menu li.hasChild>.show-subnav {
  background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M5%203l3.057-3%2011.943%2012-11.943%2012-3.057-3%209-9z%22%2F%3E%3C%2Fsvg%3E') no-repeat scroll;
  background-position: right 20px center;
  background-size: 20px 20px;
}

#sm_menu_ham {
  cursor             : pointer;
  height             : 25px;
  position           : absolute;
  right              : 5px;
  transform          : rotate(0deg);
  transition         : all 0.5s ease-in-out 0s;
  width              : 30px;
  top                : 0;
  bottom             : 0;
  margin             : auto;
  right              : 15px;
  z-index            : 10003;
}
#sm_menu_ham span {
  background-color: #fff;
  border-radius: 5px;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out 0s;
  width: 30px;
}

#sm_menu_ham.open span {
  background-color: #fff;
}

#sm_menu_ham span:nth-child(1) {
  top: 0;
}
#sm_menu_ham span:nth-child(2),
#sm_menu_ham span:nth-child(3) {
  top: 10px;
}
#sm_menu_ham span:nth-child(4) {
  top: 20px;
}
#sm_menu_ham.open span:nth-child(1) {
  left: 50%;
  top: 18px;
  width: 0;
}
#sm_menu_ham.open span:nth-child(2) {
  transform: rotate(45deg);
}
#sm_menu_ham.open span:nth-child(3) {
  transform: rotate(-45deg);
}
#sm_menu_ham.open span:nth-child(4) {
  left  : 50%;
  top   : 18px;
  width : 0;
}

@media (min-width: 1024px) {
  #sm_menu_ham,
  .sm_menu_outer {
    display: none;
  }
  .mm_main_nav {
    display: flex;
  }
}

.hasChild{
  position: relative;

  &.active{
    position: static;
  }
}

.show-subnav{
  display  : inline-block;
  position : absolute;
  right    : 0;
  top      : 0;
  bottom   : 0;
  margin   : auto;
  width    : 60px;
  cursor: pointer;
}

*/