/*==================================
=            Typography            =
==================================*/

// Base reset
// ----------

body {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: $body-color;
  background-image: url(/assets/images/img-bg.gif);
  background-position: bottom center;
  background-size: contain;
  margin-bottom: -20rem;
  background-repeat: no-repeat;
  background-color: $lighterblue;
}
a {color: $blue;}

.display-text {
  font-weight: 400 !important;
  font-size: 2.5rem;
}

.content-title {
  margin-top: 0;
  margin-bottom: 1.5rem;
  position: relative;
  color: $primary;
  text-indent: 2rem;
  &:before, &:after {
    position          : absolute;
    content           : '';
    width             : 100%;
    height            : 100%;
  }
  &:before {
    background: url(/assets/images/img-pinRed.png) no-repeat;
    top               : -0.3em;
    left              : 0.3em;
  }
  &:after{
    background: url(/assets/images/img-pinBlue.png) no-repeat;
    top               : -0.3em;
    left               : 95%;
  }
}
.content-title span {
  position: absolute;
  top: -1em; bottom: -1em;
  left: -1em; right: -1em;
}




// Add background elements to titles
// .boxed-content{
  .title {
    @include clearfix;
    display     : inline-block;
    padding     : 0.2rem 1rem;
    color       : $yellow;
    z-index     : 10;
    width       : 100%;
    font-family : $font-family-heading;

    border-radius     : 0.9rem 0.9rem 0 0;
    background-repeat : no-repeat;
    background        : $blue;
    font-size         : $titleText;
    font-weight       : 400;
    line-height       : 1.8;
  }
//}

.section-header .title {
  background          : $blue;
  color               : $yellow;
}



// Lists
// -----

.list {
  list-style-type: none;
  margin:0; padding: 0;
}
.list-inline li {
  display: inline-block;
}




// Helpers
// -------

.text-secondary {
  font-family: $font-family-sans-serif;
}


.text-red {
  color: $red;
}
.text-blue {
  color: $blue;
}
.text-green {
  color: $green;
}


