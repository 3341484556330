/* Grouping / Clearing */

.group:after{
	content : " ";
	display : block;
	clear   : both;
}

.clear{
	clear : both;
}

.no-bullet{
    list-style : none;
    padding    : 0;
}


.flex-vertical{
    display: flex;
    flex-direction : column;

    > div{
        flex-shrink : 0;
        flex-grow   : 0;
        flex-basis  : auto;
    }
}

.align-flex-right{
    display         : flex;
    justify-content : flex-end;
}


.icon{
    width             : 16px;
    height            : 16px;
    background-repeat : no-repeat;
    display           : inline-block;
    background-size   : contain;
}


/*
    Add class to an element with a background image to add a gradient
    to the top and bottom of an image - use full for making text more legible
*/

.vignette{
  position : relative;

  &:before{
        content : '';
        position: absolute;
        top : 0;
        left : 0;
        bottom : 50%;
        right :0;
        margin: auto;
        background-image : linear-gradient(to bottom,rgba(0,0,0,0.45),rgba(0,0,0,0));
        z-index : 0;
    }
  &:after{
        content : '';
        position: absolute;
        top : 50%;
        left : 0;
        bottom : 0;
        right :0;
        margin: auto;
        background-image : linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,0.45));
        z-index : 0;
    }
}