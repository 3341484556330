// #back-to-top {
// 	z-index          : 4001;
// 	display          : block;
// 	width            : 40px;
// 	height           : 40px;
// 	transition       : 1s;
// 	text-decoration  : none;
// 	position         : fixed;
// 	right            : 20px;
// 	bottom           : 20px;
// 	border-radius    : .8em;
// 	background-color : rgba(0, 0, 0, .7);
// 	box-shadow       : 0 0 15px rgba(0, 0, 0, .3) inset;

// }

// #back-to-top a:hover {
// 	color: #000;
// }

// .back-to-top{
// 	//fill : $brand-primary;
//     background-repeat: no-repeat;
//     background-size: cover;
//     width: 100%;
//     height: 100%;
//     display: inline-block;
// }

.simplesearch-results{
    font-weight: 700;
}
.simplesearch-paging{
    margin-bottom: 2rem;
}

.simplesearch-result-pages{
    margin-right: 1rem;
}