.boxed-content{
    background-color : #FFF;
    border-radius    : 1.2rem;
    padding          : 0px;
    margin-bottom    : $spacer*2;
    border           : 2px solid $blue;

    box-shadow: 0 0 20px rgba(255,255,255,0.25);
}
.latestnews.boxed-content,
.usefulinfo.boxed-content {
    border-color: $red;
}
.boxed-content.sidebar-image {
    box-shadow: none;
    border-color: $green;
}

.boxed-content-inner {
    padding         : 1rem;
}

.pin{
    position : relative;

    &::before,
    &::after{
        position          : absolute;
        width             : 30px;
        height            : 30px;
        content           : '';
        background-size   : contain;
        background-repeat : no-repeat;
    }
}

.pin-top{
    &::before {
        top              : -0.5em;
        right            : 0.5em;
        background-image : url('/assets/images/pin-blue.png');
  }
}
.pin-bottom{
    &::after {
        bottom           : 0.5em;
        left             : 0.5em;
        background-image : url('/assets/images/pin-blue.png');
    }
}

.pin-bottom-right{
    &::after {
        bottom           : 0.5em;
        right            : 0.5em;
        background-image : url('/assets/images/pin-blue.png');
    }
}


.pin-top{
    .pin-green{
        &::before{
            background-image: url('/assets/images/pin-green.png');
        }
    }

    .pin-red{
        &::before{
            background-image: url('/assets/images/pin-red.png');
        }
    }
}

.pin-bottom{
    .pin-green{
        &::after{
            background-image: url('/assets/images/pin-green.png');
        }
    }

    .pin-red{
        &::after{
            background-image: url('/assets/images/pin-red.png');
        }
    }
}


.dojo{
    a{
        display: inline-block;
    }
    span{
        background-color : #85D60B;
        display          : inline-block;
        height           : 61px;
        font-size        : 16px;
        padding          : 1rem;
    }
}

.galitem{
    padding: 10px;
}
.sidebar-section{
    .galitem{
        width : 50%;
    }
}
/* photo gallery page */
.gallery {
    display: flex;
    flex-wrap: wrap;
    & a {
        color: $darkblue;
        text-decoration: underline;
    }
}
.gallery.boxed-content {
    border-color: #3498db;
    background-color: #fff;
    box-shadow: none;
    padding: 16px;
    padding: 1rem;
}

.pageNav ul li {
    display: inline-block;
}
/* virtual office sidebar list */
ul.virtualOffice li {
    background-image: url("/assets/images/icon-arrowsblue.png");
    background-position: right center;
    background-repeat: no-repeat;
    a {
        color: $blue;
    }
}