/*==================================
=            Navigation            =
==================================*/

/*!
 * Bootstrap 4 multi dropdown navbar ( https://bootstrapthemes.co/demo/resource/bootstrap-4-multi-dropdown-navbar/ )
 * Copyright 2017.
 * Licensed under the GPL license
 */

 /* 
 .navbar-light .navbar-nav .nav-link {
  color: white !important;
  &:hover {
    color: white !important;
  }
}
*/
.nav-link {
  padding: 0.4rem 1rem 0.2rem 1rem;
}


 

// Show subnav on hover but only on desktop
@media screen and (min-width: map-get($grid-breakpoints, $nav-breakpoint)) {

  .dropdown-item,
  .level-2 .nav-link{
    transition : all 0.3s ease 0s;
    padding: 0.25rem 1.5rem;
  }

  .dropdown:hover {

      > .dropdown-menu {
        display: block!important;
      }

      .dropdown-item,
      .level-2 .nav-link{
        padding: 0.5rem 1.5rem;
      }
  }

  .level-2:hover > .dropdown-menu {
      display : block!important;
      top     : 0;
      left    : 100%;
      background-color: darken($blue, 5%);
  }
}


/* Drop-down styles */
.dropdown-menu {
  padding          : 0px 0;
  margin           : 0 0 0;
  border           : 0px solid transition !important;
  border           : 0px solid rgba(0,0,0,.15);
  border-radius    : 0px;
  box-shadow       : none !important;
  background-color : $blue;
  min-width        : 235px;
  top               : 2.5rem;
}
.dropdown-menu.show {
  width: 100%;
  left: 0;
}
@include media-breakpoint-down(lg) {
  .dropdown-menu.show {
    padding-left: 1em;
  }
}

.dropdown-item {
  color: $white;
}
.dropdown-item:hover {
  color: $white;
  background-color: rgba(0,0,0,0.2);
}


// Navigation overrides
// --------------------


@media screen and (min-width: map-get($grid-breakpoints, $nav-breakpoint)-1){

    // Style up the nav items to look like tabs
    .navbar {

      padding-bottom: 0;

      .navbar-nav > .nav-item {
        display                 : inline-block;
        padding                 : 0 0.95rem;
        //background            : $lime;
        border-top-left-radius  : $tab-radius*0.8;
        border-top-right-radius : $tab-radius*0.8;
        color                   : $white;
        font-size               : 0.9rem;
        font-weight             : 400;

        &:not(:last-child) {
          margin-right: $spacer / 2;
        }


      }
    }
    @for $i from 1 through length($nav-text) {
      .navbar .navbar-nav .nav-item:nth-child(#{$i}) .nav-link {
        color : nth($nav-text, $i);
      }
    }
    // Dynamically add nav colors from 'main.scss'
    @for $i from 1 through length($nav-colors) {
      .navbar .navbar-nav > .nav-item:nth-child(#{$i}){
        background-color : nth($nav-colors, $i);
        border-bottom    : 9px solid nth($nav-colors, $i);

       // .dropdown-item:hover,
       // .dropdown-toggle:hover {
          /*background-color: nth($nav-colors, $i);*/
       //@extend }

        &.active{
          border-bottom : 9px solid darken(nth($nav-colors, $i), 10%);
        }
      }
    }
}


@media screen and (min-width: 1106px){
  .navbar .navbar-nav > .nav-item {

    border-top-left-radius  : $tab-radius*0.85;
    border-top-right-radius : $tab-radius*0.85;
    font-size : 1rem;
    padding   : 0 0.95rem;

  }
}



@media screen and (min-width: 1200px) {
  .navbar .navbar-nav > .nav-item {
    border-top-left-radius  : $tab-radius;
    border-top-right-radius : $tab-radius;

    padding: 0 0.95rem;

    font-size : 1.125rem;
  }
}

.tab-content > .tab-pane {
  padding: $spacer;
  overflow: hidden;
  display: flex;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  text-decoration: none;
  background-color: white;
}

@media screen and (max-width: 640px) {
  .nav-tabs .nav-link {
    padding: 0.5em;
    border: 1px solid;
  }
}

/*
$burgerColor            : #7F7F7F;
$burgerHiddenResolution : 769px;
$animationSpeed         : 0.2s;

.animated-navbar-toggler {
    position    : relative;
    display     : block;
    width       : 44px;
    height      : 44px;
    overflow    : hidden;
    white-space : nowrap;
    border-radius: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 0.3em;
    // hide text
    color       : transparent;
    z-index     : 2001;
    float       : right;
    cursor: pointer;
}

.animated-navbar-toggler span,
.animated-navbar-toggler span::before,
.animated-navbar-toggler span::after {
  // hamburger icon in CSS
    position   : absolute;
    display    : inline-block;
    height     : 3px;
    width      : 24px;
    background : $burgerColor;
}
.animated-navbar-toggler span {
  // line in the center
    position   : absolute;
    top        : 50%;
    right      : 10px;
    margin-top : -2px;
    transition : background $animationSpeed $animationSpeed;
}
.animated-navbar-toggler span::before,
.animated-navbar-toggler span::after {
  // other 2 lines
    content             : '';
    right               : 0;
    // Force Hardware Acceleration in WebKit
    transform           : translateZ(0);
    backface-visibility : hidden;
    transform-origin    : 0% 50%;
    transition          : transform $animationSpeed $animationSpeed;
}
.animated-navbar-toggler span::before {
  // menu icon top line
  top: -6px;
}
.animated-navbar-toggler span::after {
  // menu icon bottom line
  top: 6px;
}
.animated-navbar-toggler.nav-is-visible span {
  // hide line in the center
  background: rgba(46, 50, 51, 0);
}
.animated-navbar-toggler.nav-is-visible span::before,
.animated-navbar-toggler.nav-is-visible span::after {
  // keep visible other 2 lines
  background: $burgerColor;
}
.animated-navbar-toggler.nav-is-visible span::before {
  transform: translateX(4px) translateY(-3px) rotate(45deg);
}

.animated-navbar-toggler.nav-is-visible span::after {
  transform: translateX(4px) translateY(2px) rotate(-45deg);
}
@media only screen and (min-width: $burgerHiddenResolution) {
  .animated-navbar-toggler {
    display: none;
  }
}
*/

/*@include media-breakpoint-down(xl) {
  .dropdown-menu.show {
    padding-left: 1em;
  }
}*/
@media screen and (min-width:992px) {
  .dropdown-menu.show {
    padding-left: 0em;
  }
}
.non-navlink {
  color: #FFF !important;
  cursor: pointer;
}


.neighbours{
  display: flex;
  justify-content: space-between;
}