/**
 * NO CODE IN HERE IMPORT FILES ONLY
 */



@import "modules/functions";
@import 'modules/bootstrapvars';
@import 'modules/bootstrap';




// Site specific configuration
//////////////////////////////


// Header
/////////
$header-height: 6em;
$header-color : $lighterblue;
$header-border-color-top : $green;
$header-border-color-bottom : $blue;


// Slogan
$display-font-family : $font-family-sans-serif!default;

// Navigation overrides
// --------------------

$nav-colors: $green, $yellow, $darkred, $red, $lightblue, $purple;
$nav-text: $yellow, $green, $yellow, $yellow, $green, $yellow;
$tab-radius: $spacer * 2;

$nav-breakpoint : lg;

//Sidebar
// -------

$sidebar-bg           : $white;
$sidebar-header-bg    : $green;
$sidebar-header-color : $white;


// Footer
/////////

$footer-bg: $green;
$footer-button-color: $blue;
$footer-copyright : $darkblue;
$footer-text-color : $white;

// $lime:      #597E11 !default;
$coral:     #fe6d4c !default;
$burgundy:  #771728 !default;
$beige:     #FEE9C3 !default;
$khaki:     #F4D698 !default;



// Home page
////////////

$welcomeImage : ''!default;



$titleText : 1.8rem;
$leadText  : 1.25rem;



// Functions
////////////
@function set-button-text-color($color) {
  @if (lightness($color) > 50) {
    @return $black; // Lighter backgorund, return dark color
  } @else {
    @return $white; // Darker background, return light color
  }
}



@import 'modules/helpers';
// @import 'modules/icons.data.svg';
@import 'modules/navigation';
@import 'modules/mobilenav';
@import 'modules/home';
// @import 'modules/video';
@import 'modules/plugins';


// Components
@import 'components/header';
@import 'components/carousel';
@import 'components/sidebar';
@import 'components/footer';
@import 'components/typography';
@import 'components/contentelements';
//@import 'components/curriculum-topics';



@import 'modules/content';

// This should always be the last import
@import 'modules/overrides';

.dropdown-toggle:after {
  border-top-color: $white;
  border-bottom-color: $white;
  position: relative;
  top: 2rem;
  right: 50%;
  margin-right: -0.6em;
  margin-left: 0;
  vertical-align: 0.3em;
  }
  .level-2 .dropdown-toggle::after {
  border-top-color: $white;
  border-bottom-color: $white;
  top: 0.1rem;
  right: 0;
  margin-right: -0.6em;
  margin-left: 0;
  vertical-align: 0.3em;
  }