.content {
  width: 100%;
  margin: 0 auto;
}
.content section {
  background-color : $white;
  padding          : 0;
  margin-bottom    : 1.5em;
  border           : 1px solid $blue;
  border-radius    : 1em;
}

// Vertical spacing so main content fills any empty space
.body{
    display        : flex;
    flex-direction : column;
    height         : 100%;
}
img {
  max-width: 100%;
  height: auto;
}

header,
footer{
    flex-grow : 0;
    flex-shrink: 0;
}
main{
    flex-grow : 1;
    flex-shrink: 0;
}