// Color system
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;


$blue:    #133380 !default;
$indigo:  #6610f2 !default;
$purple:  #2f286e !default;
$pink:    #f600ff !default;
$darkpink:  #ca3079 !default;
$red:     #f74634 !default;
$orange:  #dd9200 !default;
$yellow:  #f6c343 !default;
$green:   #5ea24f !default;
$teal:    #20c997 !default;
$cyan:    #5b9ec5 !default;
$lime:    #8ad803 !default;
$darkblue: #2e3d8e !default;
$lightblue: #99c6ff !default;
$lighterblue: #d1e2f7 !default;
$darkred:   #9f3632 !default;

.blue {color: $blue;}
.yellow {color: $yellow;}
.red {color: $red;}
.green {color: $green;}

$primary:       $green !default;
$secondary:     $green !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;


//$body-bg:                $white !default;
$body-color:             #000 !default;

$link-color: $primary !default;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


$container-max-widths: (
  sm: 96%,
  md: 90%,
  lg: 90%,
  xl: 1140px
) !default;



$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;


$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;


$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

@import url('https://fonts.googleapis.com/css?family=Kranky|Love+Ya+Like+A+Sister&display=swap');
@import url('https://fonts.googleapis.com/css?family=Schoolbell&display=swap');

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-family-heading:         'Love Ya Like A Sister', cursive !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.1 !default;
$h2-font-size:                $font-size-base * 1.9 !default;
$h3-font-size:                $font-size-base * 1.3 !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base * 1 !default;
$h6-font-size:                $font-size-base !default;

@import "../../../node_modules/bootstrap/scss/variables";